import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { login, logout } from "./store/infoSlice";
import Header from "./pages/Header"; // Import the Header component
import About from "./pages/About";
import Auth from "./pages/OAuth";
import UserInput from "./pages/UserInput";
import { useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Check authentication on mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/auth/isAuthenticated`,
          {
            credentials: "include",
          }
        );
        const data = await response.json();
        if (data.authenticated) {
          dispatch(login()); // Update Redux state
        } else {
          dispatch(logout()); // Update Redux state
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
        dispatch(logout()); // Ensure state reflects unauthenticated status
      }
    };
    checkAuthStatus();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          {/* Redirect to /login if not authenticated */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/userInput" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Auth />} />
          <Route
            path="/userInput"
            element={isAuthenticated ? <UserInput /> : <Navigate to="/login" />}
          />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
