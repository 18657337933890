import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCity, setSelectedDate, toggleCalendar } from "../store/userSlice";
import TextAPISearch from "../components/TextAPISearch";

function UserInput() {
  const dispatch = useDispatch();

  // Access Redux state
  const city = useSelector((state) => state.user.city);
  const date = useSelector((state) => state.user.selectedDate);
  const showCalendar = useSelector((state) => state.user.showCalendar);

  const handleCityChange = (event) => {
    dispatch(setCity(event.target.value)); // Update city in Redux
  };

  const handleDateChange = (event) => {
    dispatch(setSelectedDate(event.target.value)); // Update date in Redux
  };

  const handleStartPlanning = () => {
    console.log("Start planning for", city, "on", date);
    dispatch(toggleCalendar(true)); // Show the calendar
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      {!showCalendar ? (
        <div className="bg-white p-8 rounded-lg shadow-lg w-80 text-center">
          <h1 className="text-2xl font-semibold mb-6 text-gray-800">
            Plan Your Visit
          </h1>
          <div className="mb-4">
            <label className="block text-gray-600 mb-1" htmlFor="city">
              City name
            </label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={handleCityChange}
              placeholder="Enter city name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-600 mb-1" htmlFor="date">
              Date
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={handleDateChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <button
            onClick={handleStartPlanning}
            className="w-full bg-blue-600 text-white py-2 rounded-md font-semibold hover:bg-blue-700 transition-colors"
          >
            Start Planning
          </button>
        </div>
      ) : (
        <TextAPISearch />
      )}
    </div>
  );
}

export default UserInput;
