import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setRestaurants,
  setLandmarks,
  setFinalArray,
} from "../store/userSlice";
import LLMText from "./LLMText";

function TextAPISearch() {
  const dispatch = useDispatch();

  // Access Redux state
  const city = useSelector((state) => state.user.city);
  const restaurants = useSelector((state) => state.user.restaurants);
  const landMarks = useSelector((state) => state.user.landMarks);

  const serviceRef = useRef(null);

  // Helper function to format the API response
  const formatArray = (array) =>
    array.map((place) => ({
      place_id: place.place_id,
      name: place.name,
      address: place.formatted_address,
    }));

  // Load Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  // Initialize PlacesService
  useEffect(() => {
    if (isLoaded && window.google && !serviceRef.current) {
      serviceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [isLoaded]);

  // Fetch restaurants
  const fetchRestaurants = useCallback(() => {
    if (serviceRef.current) {
      const query = `Restaurants in ${city}`;
      const request = {
        query,
        fields: ["name", "place_id", "formatted_address"],
      };

      serviceRef.current.textSearch(request, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          const formattedResults = formatArray(results.slice(0, 15));
          dispatch(setRestaurants(formattedResults));
        } else {
          dispatch(setRestaurants([]));
          console.error("No restaurants found or error occurred:", status);
        }
      });
    }
  }, [city, dispatch]);

  // Fetch landmarks
  const fetchLandMarks = useCallback(() => {
    if (serviceRef.current) {
      const query = `Landmarks in ${city}`;
      const request = {
        query,
        fields: ["name", "place_id", "formatted_address"],
      };

      serviceRef.current.textSearch(request, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          const formattedResults = formatArray(results.slice(0, 15));
          dispatch(setLandmarks(formattedResults));
        } else {
          dispatch(setLandmarks([]));
          console.error("No landmarks found or error occurred:", status);
        }
      });
    }
  }, [city, dispatch]);

  // Fetch data when `isLoaded` or `city` changes
  useEffect(() => {
    if (isLoaded) {
      fetchRestaurants();
      fetchLandMarks();
    }
  }, [isLoaded, fetchRestaurants, fetchLandMarks]);

  // Update `finalArray` in Redux when restaurants or landmarks change
  useEffect(() => {
    if (restaurants.length && landMarks.length) {
      const combinedArray = [...restaurants, ...landMarks];
      dispatch(setFinalArray(combinedArray));
    }
  }, [restaurants, landMarks, dispatch]);

  return <div>{isLoaded ? <LLMText /> : <h2>Loading results...</h2>}</div>;
}

export default TextAPISearch;
