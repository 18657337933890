import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetUserState } from "../store/userSlice"; // Import reset action
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function Calendar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Access Redux state
  const date = useSelector((state) => state.user.selectedDate); // Selected date
  const places = useSelector((state) => state.user.finalArray); // Final places array

  const [, setEvents] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [isPlanPushed, setIsPlanPushed] = useState(false);

  // Fetch and prepare the day's plan on page load
  const prepareDayPlan = async () => {
    try {
      // Fetch existing events for the selected date
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/auth/events?date=${date}`,
        { credentials: "include" }
      );

      if (response.ok) {
        const data = await response.json();
        const formattedEvents = data.map((event) => ({
          title: event.summary,
          start: event.start.dateTime || event.start.date,
          end: event.end.dateTime || event.end.date,
        }));

        // Add new events from `places` to free slots
        const plannedEvents = addPlacesToDay(formattedEvents);

        setEvents(formattedEvents); // Save original events
        setCalendarEvents(plannedEvents); // Save preview events
      } else {
        console.error("Failed to fetch events.");
      }
    } catch (error) {
      console.error("Error preparing day plan:", error);
    }
  };

  // Add places to free slots and return the updated list of events
  const addPlacesToDay = (existingEvents) => {
    const updatedEvents = [...existingEvents];
    let currentDate = new Date(`${date}T08:00:00`); // Start from 8 AM

    for (const place of places) {
      const nextFreeSlot = findNextFreeSlot(updatedEvents, currentDate);
      if (nextFreeSlot) {
        updatedEvents.push({
          title: `Visit: ${place.name}`,
          start: nextFreeSlot.start.toISOString(),
          end: nextFreeSlot.end.toISOString(),
          location: place.address,
        });
        currentDate = nextFreeSlot.end; // Move to the next free slot
      }
    }

    return updatedEvents;
  };

  // Helper function to find the next free one-hour slot
  const findNextFreeSlot = (events, startFrom) => {
    let slotStart = new Date(startFrom);
    slotStart.setMinutes(0, 0, 0); // Start at the beginning of the hour

    while (true) {
      const slotEnd = new Date(slotStart);
      slotEnd.setHours(slotEnd.getHours() + 1);

      const isConflict = events.some(
        (event) =>
          new Date(event.start) < slotEnd && new Date(event.end) > slotStart
      );

      if (!isConflict) {
        return { start: slotStart, end: slotEnd };
      }

      slotStart.setHours(slotStart.getHours() + 1); // Move to the next hour
    }
  };

  // Push the planned schedule to Google Calendar
  const handlePushToCalendar = async () => {
    try {
      await Promise.all(
        calendarEvents
          .filter((event) => event.title.startsWith("Visit:")) // Only new events
          .map((event) =>
            fetch(`${process.env.REACT_APP_BACKEND}/auth/add-event`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
              body: JSON.stringify(event),
            })
          )
      );

      setIsPlanPushed(true); // Mark plan as pushed
      alert("Your plan has been successfully added to Google Calendar!");
    } catch (error) {
      console.error("Error pushing events to calendar:", error);
    }
  };

  // Reset Redux state and navigate to /userInput
  const handleNewPlan = () => {
    dispatch(resetUserState()); // Reset user state
    navigate("/userInput");
  };

  // Prepare the day's plan on component mount
  useEffect(() => {
    if (date) prepareDayPlan();
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-8 px-4">
      <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-4xl text-center">
        <h2 className="text-4xl font-bold text-blue-700 mb-4">
          Your Day Plan for {date}
        </h2>
        <p className="text-gray-600 mb-6">
          Below is a preview of your day. Events from your calendar and planned
          visits are shown together.
        </p>

        <div className="bg-white p-4 rounded-xl shadow-lg mb-8">
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridDay"
            events={calendarEvents}
            headerToolbar={{
              left: "",
              center: "title",
              right: "", // Only show the title
            }}
            height="auto"
            contentHeight="auto"
            validRange={{
              start: date,
              end: date, // Restrict to the selected day
            }}
          />
        </div>

        <div className="flex gap-4 justify-center">
          {!isPlanPushed ? (
            <button
              onClick={handlePushToCalendar}
              className="bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200 shadow-lg hover:shadow-xl"
            >
              Push Plan to Google Calendar
            </button>
          ) : (
            <p className="text-green-600 font-semibold">
              Your plan has been added to Google Calendar!
            </p>
          )}
          <button
            onClick={handleNewPlan}
            className="bg-gray-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-700 transition-colors duration-200 shadow-lg hover:shadow-xl"
          >
            Make a New Plan
          </button>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
