// import { useState, useEffect } from "react";
// import Calendar from "./Calendar";

// function LLMText({ places, date }) {
//   const [loadedPlaces, setLoadedPlaces] = useState([]);
//   const [selectedPlaces, setSelectedPlaces] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [showCalendar, setShowCalendar] = useState(false);
//   console.log(date)

//   useEffect(() => {
//     const fetchAIPlaces = async () => {
//       setError(null);
//       setLoading(true);

//       try {
//         const res = await fetch(`${process.env.REACT_APP_BACKEND}/backend`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             places: places,
//           }),
//         });

//         if (!res.ok) {
//           throw new Error("Unable to fetch response from the API");
//         }

//         const data = await res.json();
//         setLoadedPlaces(data.topPlaces); // Populate loaded places
//       } catch (error) {
//         console.error(error.message);
//         setError("Failed to fetch response from the AI API");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAIPlaces();
//   }, [places]);

//   // Toggle selection of a place
//   const togglePlaceSelection = (place) => {
//     setSelectedPlaces((prevSelected) => {
//       const isSelected = prevSelected.find(
//         (selectedPlace) => selectedPlace.name === place.name
//       );
//       if (isSelected) {
//         return prevSelected.filter(
//           (selectedPlace) => selectedPlace.name !== place.name
//         );
//       } else {
//         return [...prevSelected, place];
//       }
//     });
//   };

//   // Handle navigation to the calendar page
//   const handleShowCalendar = () => {
//     setShowCalendar(true); // Switch to the calendar view
//   };

//   // Display the loaded places and allow user interaction
//   return (
//     <div className="flex flex-col items-center min-h-screen bg-gray-100 py-8 px-4">
//       {loading ? (
//         <p className="text-blue-500 text-lg font-semibold">Loading places...</p>
//       ) : error ? (
//         <p className="text-red-500">{error}</p>
//       ) : showCalendar ? (
//         <Calendar places={selectedPlaces} date={date} />
//       ) : (
//         <>
//           <h2 className="text-3xl font-bold text-blue-800 mb-4">
//             AI-Suggested Places for You
//           </h2>
//           <h3 className="text-xl text-gray-600 mb-6">
//             Select the ones that interest you
//           </h3>

//           <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-4xl">
//             {loadedPlaces.map((place, index) => (
//               <div
//                 key={index}
//                 onClick={() => togglePlaceSelection(place)}
//                 className={`p-4 border rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-transform transform hover:scale-105 ${
//                   selectedPlaces.some(
//                     (selected) => selected.name === place.name
//                   )
//                     ? "bg-blue-100 border-blue-500"
//                     : "bg-white"
//                 }`}
//               >
//                 <strong className="text-lg text-blue-700">{place.name}</strong>
//                 <p className="text-sm text-gray-600">{place.address}</p>
//               </div>
//             ))}
//           </div>
//           <button
//             onClick={handleShowCalendar}
//             className="mt-6 bg-green-600 text-white py-2 px-6 rounded-lg font-semibold shadow-md hover:bg-green-700 transition-colors"
//           >
//             Go to Calendar
//           </button>
//         </>
//       )}
//     </div>
//   );
// }

// export default LLMText;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFinalArray } from "../store/userSlice"; // Redux action
import Calendar from "./Calendar";

function LLMText() {
  const dispatch = useDispatch();

  const places = useSelector((state) => state.user.finalArray); // Access places (finalArray) from Redux
  const [loadedPlaces, setLoadedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    const fetchAIPlaces = async () => {
      setError(null);
      setLoading(true);

      try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/backend`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            places: places, // Use Redux places
          }),
        });

        if (!res.ok) {
          throw new Error("Unable to fetch response from the API");
        }

        const data = await res.json();
        setLoadedPlaces(data.topPlaces); // Populate loaded places
      } catch (error) {
        console.error(error.message);
        setError("Failed to fetch response from the AI API");
      } finally {
        setLoading(false);
      }
    };

    fetchAIPlaces();
  }, [places]);

  // Toggle selection of a place
  const togglePlaceSelection = (place) => {
    setSelectedPlaces((prevSelected) => {
      const isSelected = prevSelected.find(
        (selectedPlace) => selectedPlace.name === place.name
      );
      if (isSelected) {
        return prevSelected.filter(
          (selectedPlace) => selectedPlace.name !== place.name
        );
      } else {
        return [...prevSelected, place];
      }
    });
  };

  // Handle navigation to the calendar page
  const handleShowCalendar = () => {
    // Update Redux finalArray with selected places
    dispatch(setFinalArray(selectedPlaces));
    setShowCalendar(true); // Switch to the calendar view
  };

  // Display the loaded places and allow user interaction
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 py-8 px-4">
      {loading ? (
        <p className="text-blue-500 text-lg font-semibold">Loading places...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : showCalendar ? (
        <Calendar />
      ) : (
        <>
          <h2 className="text-3xl font-bold text-blue-800 mb-4">
            AI-Suggested Places for You
          </h2>
          <h3 className="text-xl text-gray-600 mb-6">
            Select the ones that interest you
          </h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-4xl">
            {loadedPlaces.map((place, index) => (
              <div
                key={index}
                onClick={() => togglePlaceSelection(place)}
                className={`p-4 border rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-transform transform hover:scale-105 ${
                  selectedPlaces.some(
                    (selected) => selected.name === place.name
                  )
                    ? "bg-blue-100 border-blue-500"
                    : "bg-white"
                }`}
              >
                <strong className="text-lg text-blue-700">{place.name}</strong>
                <p className="text-sm text-gray-600">{place.address}</p>
              </div>
            ))}
          </div>
          <button
            onClick={handleShowCalendar}
            className="mt-6 bg-green-600 text-white py-2 px-6 rounded-lg font-semibold shadow-md hover:bg-green-700 transition-colors"
          >
            Go to Calendar
          </button>
        </>
      )}
    </div>
  );
}

export default LLMText;
